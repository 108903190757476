export const state = () => ({
  data: {},
})

export const getters = {
  doesExist: (state) => (id) => {
    return state.data[id] !== undefined
  },

  hasMetrics: (state) => (id) => {
    return state.data[id].metrics.length > 0
  },

  getMonitor: (state) => (id) => {
    const currentState = JSON.parse(JSON.stringify(state.data))

    return currentState[id]
  },

  getMetrics: (state) => (id) => {
    const currentState = JSON.parse(JSON.stringify(state.data))
    if (!currentState[id]) return []
    const dnsAvg = {
      name: 'DNS Average',
      data: currentState[id].metrics.map((metric) => {
        return {
          x: new Date(metric.time).getTime(),
          y: metric.dns_avg
            ? Math.round(metric.dns_avg * 100) / 100
            : metric.dns_avg,
        }
      }),
    }
    const connectAvg = {
      name: 'Connect Average',
      data: currentState[id].metrics.map((metric) => {
        return {
          x: new Date(metric.time).getTime(),
          y: metric.connect_avg
            ? Math.round(metric.connect_avg * 100) / 100
            : metric.connect_avg,
        }
      }),
    }
    const tlsAvg = {
      name: 'TLS Average',
      data: currentState[id].metrics.map((metric) => {
        return {
          x: new Date(metric.time).getTime(),
          y: metric.tls_avg
            ? Math.round(metric.tls_avg * 100) / 100
            : metric.tls_avg,
        }
      }),
    }
    const responseAvg = {
      name: 'Response Average',
      data: currentState[id].metrics.map((metric) => {
        return {
          x: new Date(metric.time).getTime(),
          y: metric.response_avg
            ? Math.round(metric.response_avg * 100) / 100
            : metric.response_avg,
        }
      }),
    }

    return [dnsAvg, connectAvg, tlsAvg, responseAvg]
  },
}

export const mutations = {
  add: (state, monitor) => {
    Object.assign(state, {
      data: {
        ...state.data,
        [monitor.monitor.id]: monitor,
      },
    })
  },
}

export const actions = {
  async fetchMonitorInfo(context, id) {
    const fetchInfo = fetch(`/api/v1/status-page/monitor/${id}/info`).then(
      (res) => res.json()
    )
    const fetchMetrics = fetch(
      `/api/v1/status-page/monitor/${id}/metrics/30d`
    ).then((res) => res.json())

    const [info, metrics] = await Promise.all([fetchInfo, fetchMetrics])

    context.commit('add', { ...info, metrics })
  },
}
