export const state = () => ({
  init: false,
  data: {},
})

export const getters = {
  isInit: (state) => {
    return state.init
  },

  getConfig: (state) => {
    return state.data.config
  },

  getMonitors: (state) => {
    return state.data.monitors
  },
}

export const mutations = {
  init: (state, info) => {
    Object.assign(state, {
      data: info,
    })
    Object.assign(state, {
      init: true,
    })
  },
}

export const actions = {
  init(context) {
    return fetch('/api/v1/status-page/info')
      .then((res) => res.json())
      .then((info) => {
        context.commit('init', info)
      })
  },
}
